import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    folders: [],
    uploadList: []
  },
  getters: {
    getFolders (state) {
      return state.folders
    },
    getUploadList (state) {
      return state.uploadList
    }
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = payload.userInfo
    },
    setFolders (state, payload) {
      state.folders = payload.folders
    },
    setUploadList (state, payload) {
      state.uploadList = payload.uploadList
    }
  },
  actions: {
  },
  modules: {
  }
})
