<template>
  <t-dialog header="修改密码" :visible="show" @close="handleDialogClose" :closeOnOverlayClick="false" confirmOnEnter @confirm="submitForm" :confirmLoading="loading">
    <t-form :data="formData" :rules="rules" ref="form" @reset="onReset" @submit="onSubmit" :colon="true" label-align="top" resetType="initial">
      <t-form-item name="oldPasswords" label="当前账户密码">
        <t-input type="password" clearable v-model="formData.oldPasswords" placeholder="请输入当前账户密码">
          <lock-on-icon slot="prefix-icon"></lock-on-icon>
        </t-input>
      </t-form-item>
      <t-form-item name="newPasswords" label="新密码">
        <t-input type="password" clearable v-model="formData.newPasswords" placeholder="请输入新密码">
          <lock-on-icon slot="prefix-icon"></lock-on-icon>
        </t-input>
      </t-form-item>
      <t-form-item name="confirmPasswords" label="再次输入新密码">
        <t-input type="password" clearable v-model="formData.confirmPasswords" placeholder="请再次输入新密码">
          <lock-on-icon slot="prefix-icon"></lock-on-icon>
        </t-input>
      </t-form-item>
    </t-form>
  </t-dialog>
</template>

<script>
import { LockOnIcon } from 'tdesign-icons-vue'
export default {
  name: 'UpdatePasswords',
  props: ['show'],
  components: {
    LockOnIcon
  },
  data () {
    return {
      loading: false,
      formData: {
        oldPasswords: '',
        newPasswords: '',
        confirmPasswords: ''
      },
      rules: {
        oldPasswords: [
          { whitespace: true, message: '当前密码不能为空' },
          { required: true, message: '当前密码必填', type: 'error' }
        ],
        newPasswords: [
          { whitespace: true, message: '新密码不能为空' },
          { required: true, message: '密码必填', type: 'error' },
          { pattern: /^[a-zA-Z0-9]{6,20}$/, message: '密码只能是字母或数字且长度在6~20字内', type: 'warning' }
        ],
        confirmPasswords: [
          {
            validator: (val) => val === this.formData.newPasswords,
            message: '两次输入的新密码不相同',
            type: 'warning'
          }
        ]
      }
    }
  },
  methods: {
    handleDialogClose () {
      this.restForm()
      this.$emit('close')
    },
    submitForm () {
      this.$refs.form.submit()
    },
    restForm () {
      this.loading = false
      this.$refs.form.reset()
    },
    onReset () {},
    onSubmit ({ validateResult, firstError }) {
      if (validateResult === true) {
        this.updatePasswords(this.formData.oldPasswords, this.formData.newPasswords)
      } else {
        console.log('Errors: ', validateResult)
      }
    },
    updatePasswords (oldPasswords, newPasswords) {
      const that = this
      that.loading = true
      that.$http.post('update_passwords', { oldPasswords, newPasswords }).then(function (resp) {
        if (resp.data.error === 0) {
          that.$message.success('密码修改成功')
          that.restForm()
          that.$emit('close')
        }
        that.loading = false
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
