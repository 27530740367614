import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseView from '../views/BaseView.vue'
import IndexView from '../views/IndexView.vue'
import MyFolder from '../views/MyFolder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    component: BaseView,
    children: [
      {
        path: 'folder/:idx?',
        name: 'Index',
        meta: { title: '文件中心' },
        component: IndexView
      },
      {
        path: 'myfolder/:idx?',
        name: 'MyFolder',
        meta: { title: '我的文件中心' },
        component: MyFolder
      },
      {
        path: '/users',
        name: 'Users',
        meta: { title: '用户列表' },
        component: () => import('../views/UsersView.vue')
      },
      {
        path: '/fileScopeTag',
        name: 'FileScopeTag',
        meta: { title: '文件范围标签' },
        component: () => import('../views/FileScopeTag.vue')
      },
      {
        path: '/search/:keywords?',
        name: 'Search',
        meta: { title: '搜索' },
        component: () => import('../views/SearchResult.vue')
      },
      {
        path: 'recycle',
        name: 'Recycle',
        meta: { title: '回收站' },
        component: () => import('../views/RecycleView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/folders/:idx?',
    name: 'Folders',
    meta: { title: '文件中心' },
    component: () => import('../views/MView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'Wegrow'
  }
  if (to.name !== 'Login' && token == null) next({ name: 'Login' })
  else next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
