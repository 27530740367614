import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import TDesign from 'tdesign-vue'

// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css'

// 引入axios
import http from './axios'
Vue.prototype.$http = http

Vue.use(TDesign)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
