<template>
  <div class="base-page">
      <div class="left-area">
        <t-menu theme="light" defaultValue="item2" :collapsed="collapsed" height="550px">
          <template #logo>
            <img :width="collapsed ? 35 : 136" :src="iconUrl" alt="logo" class="logo-img" />
          </template>
          <t-menu-item v-if="userInfo.role === 1" value="myfolder" @click="goto('MyFolder')"><template #icon><icon name="app" /></template> 所有文件</t-menu-item>
          <t-menu-group v-if="userInfo.role === 1" title="我的文件夹">
            <SelfMenu :folder="item" v-for="item in folderMenu" :key="item.id" routeName="MyFolder" />
          </t-menu-group>
          <t-menu-item v-if="userInfo.role !== 1" value="all" @click="goto('Index')"><template #icon><icon name="app" /></template> 所有文件</t-menu-item>
          <t-menu-group v-if="userInfo.role !== 1" title="文件夹">
            <SelfMenu :folder="item" v-for="item in folderMenu" :key="item.id" routeName="Index" />
            <t-menu-item value="Recycle" @click="goto('Recycle')"><template #icon><icon name="delete" /></template>回收站</t-menu-item>
          </t-menu-group>
          <t-menu-group title="用户" v-if="userInfo.role === 3">
            <t-submenu title="用户管理"><template #icon><icon name="user-1" /></template>
              <t-menu-item value="users" :to="{name: 'Users'}">用户列表</t-menu-item>
              <t-menu-item value="fileScopeTag" :to="{name: 'FileScopeTag'}">文件可见范围标签</t-menu-item>
            </t-submenu>
          </t-menu-group>
          <t-menu-group title="传输"  v-if="userInfo.role !== 1">
            <t-menu-item value="transform" @click="showTransformList">
              <template #icon><icon name="cloud-upload" /></template>
              <span>传输列表<t-tag theme="danger" size="small" v-show="uploadedList.length > 0" style="margin-left:10px">{{uploadedNum}} / {{uploadedList.length}}</t-tag></span>
            </t-menu-item>
          </t-menu-group>
          <t-menu-group title="更多">
            <t-menu-item value="item3" @click="showUpdatePasswords = true"><template #icon><icon name="user-setting" /></template>个人设置</t-menu-item>
            <t-menu-item value="item4" @click="goto('Login')"><template #icon><icon name="login" /></template>登录页</t-menu-item>
          </t-menu-group>
          <template #operations>
            <t-button variant="text" shape="square"  @click.native="changeCollapsed"><icon name="view-list" /></t-button>
            <span v-show="!collapsed" class="user-name">{{ userInfo.name }}</span>
          </template>
        </t-menu>
      </div>
      <div class="right-area">
        <router-view/>
      </div>
      <t-drawer :visible.sync="drawer.transform" :closeBtn="true" size="400px" :cancelBtn="{content:'收起',theme: 'primary'}" :confirmBtn="null">
        <template #header>
          <div>传输列表<t-tag theme="danger" size="small" v-show="uploadedList.length > 0" style="margin-left:10px">{{uploadedNum}} / {{uploadedList.length}}</t-tag></div>
        </template>
        <div v-for="(file, index) in uploadedList" :key="index" class="process-item">
          <UploadProcess :file="file" @toFolder="handleGotoFolder" @continiue="hanldeUploadContinue(file)" @pause="handleUploadPause(file)" @cancel="handleUploadCancel(file, index)" />
        </div>
      </t-drawer>
      <UpdatePasswords :show="showUpdatePasswords" @close="showUpdatePasswords = false" />
  </div>
</template>
<script>
import { Icon } from 'tdesign-icons-vue'
import SelfMenu from '@/components/SelfMenu.vue'
import UpdatePasswords from '@/components/user/UpdatePasswords.vue'
import UploadProcess from '@/components/fileList/UploadProcess.vue'
const OSS = require('ali-oss')

export default {
  components: {
    Icon,
    SelfMenu,
    UploadProcess,
    UpdatePasswords
  },
  data () {
    return {
      collapsed: false,
      iconUrl: '/images/common/logo-black.png',
      userInfo: {},
      activeMenu: 0,
      drawer: {
        transform: false
      },
      uploadedList: [],
      sts: {},
      showUpdatePasswords: false
    }
  },
  computed: {
    folderMenu () {
      return this.findChildren(0, this.folders)
    },
    folders () {
      return this.$store.getters.getFolders
    },
    uploadList () {
      return this.$store.getters.getUploadList
    },
    uploadedNum () {
      let num = 0
      for (let i = 0; i < this.uploadedList.length; i++) {
        const item = this.uploadedList[i]
        if (item.percent === 100) {
          num++
        }
      }
      return num
    }
  },
  watch: {
    uploadList (newV, old) {
      for (let i = 0; i < newV.length; i++) {
        const file = newV[i]
        let inList = false
        for (let j = 0; j < this.uploadedList.length; j++) {
          const item = this.uploadedList[j]
          if (file.name === item.name && file.pid === item.pid) {
            // 文件已存在uploadedList中
            inList = true
            break
          }
        }
        if (inList === false) {
          const objName = this.makeObjectName(file.name)
          const temp = { raw: file.raw, name: file.name, objName: objName, size: file.size, type: 'file', pid: file.pid, pname: file.pname, percent: 0, status: 'uploading', url: '' }
          if (file.size > 10240000) {
            temp.client = this.makeClient()
            temp.uploadType = 'multiPart'
            const p = this.uploadedList.push(temp)
            this.uploadBigFile(this.uploadedList[p - 1])
          } else {
            temp.uploadType = 'simple'
            const p = this.uploadedList.push(temp)
            this.uploadSmallFile(this.uploadedList[p - 1])
          }
        }
      }
    }
  },
  methods: {
    makeClient () {
      const sts = this.sts
      const that = this
      const client = new OSS({
        region: process.env.VUE_APP_ALIYUN_OSS_REGION,
        accessKeyId: sts.accessKeyId,
        accessKeySecret: sts.accessKeySecret,
        stsToken: sts.stsToken,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const resp = await that.$http.get('aliyun/osssts')
          if (resp.data.code === 0) {
            return {
              accessKeyId: resp.data.AccessKeyId,
              accessKeySecret: resp.data.AccessKeySecret,
              stsToken: resp.data.SecurityToken
            }
          } else {
            return false
          }
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 3000,
        // 填写Bucket名称。
        bucket: process.env.VUE_APP_ALIYUN_OSS_BUCKET
      })
      return client
    },
    hanldeUploadContinue (file) {
      this.$message.success('文件继续开始上传')
      file.status = 'uploading'
      this.uploadBigFile(file, file.abortCheckpoint)
    },
    handleUploadPause (file) {
      file.status = 'pause'
      file.client.cancel()
    },
    handleUploadCancel (file, index) {
      const that = this
      file.status = 'cancel'
      file.client.cancel()
      file.client.abortMultipartUpload(file.abortCheckpoint.name, file.abortCheckpoint.uploadId).then(function (r) {
        that.deleteUploadItem(file.name, file.pid)
        that.uploadedList.splice(index, 1)
        console.log(r)
      }).catch(function (error) {
        console.log('error', error)
      })
    },
    deleteUploadItem (name, pid) {
      const list = JSON.parse(JSON.stringify(this.uploadList))
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.name === name && item.pid === pid) {
          list.splice(i, 1)
        }
      }
      this.$store.commit('setUploadList', { uploadList: list })
    },
    uploadBigFile (file, checkpoint) {
      const that = this
      this.drawer.transform = true
      // 定义中断点。
      // let abortCheckpoint
      file.client.multipartUpload(file.objName, file.raw, {
        checkpoint: checkpoint,
        // 分片大小
        partSize: 1024 * 1024 * 2,
        progress: (p, cpt, res) => {
          // 为中断点赋值。
          file.abortCheckpoint = cpt
          // 获取上传进度。
          file.percent = p * 100
        },
        headers: {
          'x-oss-forbid-overwrite': true,
          'Content-Disposition': 'attachment'
        },
        callback: {
          url: process.env.VUE_APP_API_BASE_URL + 'aliyun/file_upload_callback',
          // eslint-disable-next-line no-template-curly-in-string
          body: 'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}&user=${x:user}&fileName=${x:fileName}&pid=${x:pid}',
          customValue: {
            user: that.userInfo.id,
            fileName: file.name,
            pid: file.pid
          }
        }

      }).then(function (r) {
        // console.log(r)
        if (r.res.requestUrls !== undefined && r.data.Status === 'Ok' && r.res.status === 200) {
          file.percent = 100
          file.status = 'uploaded'
          file.url = r.res.requestUrls[0].split('?')[0]
        } else {
          console.log('check')
          file.status = 'failed'
        }
      }).catch(error => {
        if (error.name === 'abort') {
          that.$message.warning(file.name + '已暂停上传')
          file.status = 'pause'
        }
        if (error.code === 'ConnectionTimeoutError') {
          that.$message.error(file.name + '上传超时')
          file.status = 'failed'
        }
        console.log(error)
      })
    },
    uploadSmallFile (file) {
      const that = this
      this.drawer.transform = true
      const options = {
        headers: {
          'x-oss-forbid-overwrite': true,
          'Content-Disposition': 'attachment'
        },
        callback: {
          url: process.env.VUE_APP_API_BASE_URL + 'aliyun/file_upload_callback',
          // eslint-disable-next-line no-template-curly-in-string
          body: 'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}&user=${x:user}&fileName=${x:fileName}&pid=${x:pid}',
          customValue: {
            user: that.userInfo.id,
            fileName: file.name,
            pid: file.pid
          }
        }
      }
      const client = that.makeClient()
      client.put(file.objName, file.raw, options).then(function (resp) {
        if (resp.data.Status === 'Ok' && resp.res.status === 200) {
          file.percent = 100
          file.status = 'uploaded'
          file.url = resp.url
        }
        console.log(resp)
      }).catch(error => {
        console.log(error)
        file.status = 'failed'
        that.$message.error('发生错误，上传失败')
      })
    },
    makeObjectName (filename) {
      const prefixRandom = Math.floor(100000 + Math.random() * 900000)
      return this.getYmd() + '/' + prefixRandom + '_' + filename
    },
    showTransformList () {
      this.drawer.transform = true
    },
    goto (name) {
      this.$router.push({ name })
    },
    handleGotoFolder (pid) {
      this.$router.push({ name: 'Index', params: { idx: pid } })
      this.drawer.transform = false
    },
    changeCollapsed () {
      this.collapsed = !this.collapsed
      this.iconUrl = this.collapsed
        ? '/images/common/logo-black.png'
        : '/images/common/logo-black.png'
    },
    getFolders () {
      const that = this
      that.$http.get('folder').then(function (resp) {
        if (resp.data.list !== undefined) {
          const list = JSON.parse(JSON.stringify(resp.data.list))
          that.$store.commit('setFolders', { folders: list })
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    findChildren (pid, list) {
      const children = []
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.parent_id === pid) {
          item.children = this.findChildren(item.id, list)
          children.push(item)
        }
      }
      return children
    },
    storeUserInfo () {
      const user = sessionStorage.getItem('user')
      this.userInfo = JSON.parse(user)
      this.$store.commit('setUserInfo', { userInfo: this.userInfo })
    },
    getYmd () {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()
      const formattedDate = year + '_' + month + '_' + day
      return formattedDate
    },
    getSTS () {
      const that = this
      this.$http.get('aliyun/osssts').then(function (resp) {
        if (resp.data.code === 0) {
          that.sts = {
            accessKeyId: resp.data.AccessKeyId,
            accessKeySecret: resp.data.AccessKeySecret,
            stsToken: resp.data.SecurityToken
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    }
  },
  mounted () {
    this.storeUserInfo()
    this.getFolders()
    this.getSTS()
  }
}
</script>

<style scoped>
.base-page{
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  display: flex;
}
.right-area{
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
}
.logo-img{
  padding-left: 20px;
}
.process-item{
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}
.user-name{
  display: inline-block;
  width: 150px;
  vertical-align: middle;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
