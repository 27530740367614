<template>
<!-- 文件夹、文件夹缩略图模式 主要用在grid -->
  <div class="file-type" @mouseenter="showOptions = true" @mouseleave="showOptions = false">
    <div>
      <t-image-viewer :images="[fileData.url]" v-if="isImage" closeOnOverlay>
        <template #trigger="{ open }">
          <t-image :src="coverImage" fit="cover" class="image-cover" @click="open" />
        </template>
      </t-image-viewer>
      <t-image :src="coverImage" fit="fill" class="image-cover" v-else @click="handleItemClick" />
    </div>
    <div class="name">
      <t-tooltip :content="showName" theme="light">
          {{showName}}
        </t-tooltip>
    </div>
    <div :class="['checkbox-wrap',(showOptions || selected)?'opacity-full':'opacity-zero']"><t-checkbox :checked="selected" @change="$emit('chose',fileData.id)"></t-checkbox></div>
    <div :class="['options',showOptions?'opacity-full':'opacity-zero']" v-show="!selected && fileData.typeof === 'file'">
      <t-dropdown trigger="click">
        <div class="dot-wrap">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <t-dropdown-menu>
          <t-dropdown-item @click="downloadFile(fileData.url,fileData.name)">下载</t-dropdown-item>
          <t-dropdown-item @click="copyUrl(fileData.url)">复制链接</t-dropdown-item>
        </t-dropdown-menu>
      </t-dropdown>
    </div>
    <div class="selected-cover" v-show="selected"></div>
  </div>
</template>

<script>
export default {
  name: 'FileType',
  props: ['fileData', 'selected'],
  data () {
    return {
      showOptions: false
    }
  },
  computed: {
    isImage () {
      const images = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp']
      return images.includes(this.fileData.extension)
    },
    coverImage () {
      let url = 'images/fileTypes/unknown.svg'
      if (this.fileData.typeof === 'folder') {
        url = 'images/fileTypes/folder.svg'
      } else {
        const images = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp']
        if (images.includes(this.fileData.extension)) {
          if (this.fileData.url) {
            url = this.fileData.url
          } else {
            url = 'images/fileTypes/img.svg'
          }
        } else {
          const fileImages = ['psd', 'ai', 'txt', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'zip', 'rar', '7z', 'mp3', 'wav', 'acc', 'flac', 'mp4', 'mov', 'avi', 'cad']
          if (fileImages.includes(this.fileData.extension)) {
            url = 'images/fileTypes/' + this.fileData.extension + '.svg'
          }
        }
      }
      return url
    },
    showName () {
      return this.fileData.name + (this.fileData.typeof === 'folder' ? '' : '.') + this.fileData.extension
    }
  },
  methods: {
    handleMouseEnter () {
      this.showOptions = true
    },
    downloadFile (url, name) {
      const link = document.createElement('a')
      link.href = url
      link.download = name
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleItemClick () {
      if (this.fileData.typeof === 'folder') {
        this.$router.push({ name: 'MyFolder', params: { idx: this.fileData.idx } })
      }
    },
    copyUrl (url) {
      const that = this
      navigator.clipboard.writeText(url).then(function () {
        that.$message.success('已复制')
      }, function () {
        that.$message.error('复制失败,建议使用最新版本的Chrome浏览器进行操作')
      })
    }
  }
}
</script>

<style scoped>
.file-type{
  text-align: center;
  width: 130px;
  height: 150px;
  padding: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.file-type:hover{
  background-color: rgb(245, 245, 246);
}
.image-cover{
  width: 100px;
  height: 80px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
}
.name{
  position: relative;
  font-size: 12px;
  padding: 10px 5px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 6;
}
.options{
  width: 30px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}
.opacity-full{
  opacity: 1;
}
.opacity-zero{
  opacity: 0;
}
.dot-wrap{
  width: 30px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 8px;
}
.dot{
  background-color: #777777;
  height: 3px;
  width: 3px;
  border-radius: 50%;
}
.selected-cover{
  position: absolute;
  border-radius: 3px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(15deg, #fff1e9, #e37318);
  opacity: 0.2;
  z-index: 5;
}
.checkbox-wrap{
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.checkbox-wrap >>>.t-checkbox__label{
  margin: 0;
}
</style>
