<template>
  <div class="index-page">
    <div class="top-bar">
      <t-breadcrumb :maxItemWidth="'150'">
        <t-breadcrumbItem v-for="(breadcrumb, index) in foldersPath" :key="index" :to="{ name: 'Index', params: { idx: breadcrumb.idx }}">
          <FolderIcon slot="icon" v-if="(index+1) !== foldersPath.length" /> <FolderOpenIcon v-else slot="icon" />
          {{breadcrumb.name}}
        </t-breadcrumbItem>
      </t-breadcrumb>
      <div class="top-bar-right">
        <t-space>
          <t-auto-complete v-model="keywords" :options="[]" placeholder="请输入关键词搜索" highlightKeyword filterable @enter="onSearch">
            <template v-if="keywords" #suffix>
              <close-circle-filled-icon @click="keywords = ''" />
            </template>
            <template #suffixIcon>
              <t-button shape="square" @click="onSearch"><search-icon /></t-button>
            </template>
          </t-auto-complete>
        </t-space>
      </div>
    </div>
    <div class="main">
      <div class="tool-area">
        <t-space align="center">
          <t-checkbox :checked="selectedItems.length === list.length && selectedItems.length !== 0" :indeterminate="selectedItems.length > 0 && selectedItems.length < list.length" @change="handleSelectAll">全选</t-checkbox>
          <span v-if="selectedItems.length > 0">已选{{selectedItems.length}}项</span>
          <span v-if="selectedItems.length === 0">共{{list.length}}项</span>
          <t-button theme="primary" size="small" variant="outline" v-show="selectedItems.length > 0 && !folderInSelectItems" @click="multiDownload"><cloud-download-icon slot="icon" />下载</t-button>
          <t-button theme="primary" size="small" variant="outline" v-show="selectedItems.length > 0 && !folderInSelectItems" @click="handleCopyMove(selectedItems,'copy')" ><file-copy-icon slot="icon"/>复制</t-button>
          <t-button theme="primary" size="small" variant="outline" v-show="selectedItems.length > 0" @click="handleCopyMove(selectedItems,'move')"><folder-move-icon slot="icon"/>剪切</t-button>
          <t-button theme="primary" size="small" variant="outline" v-show="selectedItems.length > 0" @click="handleDelete(selectedItems)"><delete1-icon slot="icon" />删除</t-button>
        </t-space>
        <div>
          <t-space align="center">
            <t-tooltip content="刷新当前文件夹" theme="light">
              <t-button size="small" variant="outline" :disabled="loading" @click="refresh">
                <RefreshIcon />
              </t-button>
            </t-tooltip>
            <t-button theme="primary" size="small" @click="createFolder">
              <add-icon slot="icon" />
              新建文件夹
            </t-button>
            <t-upload v-model="files" :autoUpload="false" allowUploadDuplicateFile theme="custom" @validate="validateSelectedFile" :size-limit="{ size: 5, unit: 'GB', message: '只能上传 {sizeLimit} GB以下的文件' }" multiple :showUploadProgress="false" :useMockProgress="false" @change="handldeUploadFileListChange">
              <template #trigger>
                <t-button size="small" ><cloud-upload-icon slot="icon"/>上传</t-button>
              </template>
            </t-upload>
          </t-space>
          <t-space size="5px" style="margin-left:15px">
            <t-tooltip content="大图模式" theme="light">
              <t-button size="small" :variant="displayType=='list'?'outline':'base'" @click="displayType='grid'">
                <grid-view-icon slot="icon"/>
              </t-button>
            </t-tooltip>
            <t-tooltip content="列表模式" theme="light">
              <t-button size="small" :variant="displayType=='grid'?'outline':'base'" @click="displayType='list'">
                <list-icon slot="icon"/>
              </t-button>
            </t-tooltip>
          </t-space>
        </div>
      </div>
      <t-loading :loading="loading" showOverlay :zIndex="9999" text="加载中" class="loading-wrap">
        <div :class="list.length>0?'list-area':''" @click.self="selectedItems=[]" v-show="displayType==='grid'">
          <div v-show="list.length === 0" class="grid-empty">空文件夹</div>
          <FileType class="list-item" :fileData="item" :selected="selectedItems.includes(item.id)" v-for="item in list" :key="item.id" @chose="handleItemChose" @rename="handleRename(item)" @delete="handleDelete([item.id])" @copyMove="(e) => handleCopyMove(e.items, e.type)" />
        </div>
        <div class="table-area" v-show="displayType==='list'">
          <t-table rowKey="index"  hover :data="list" :columns="columns" size="large" cellEmptyContent="-" height="100%">
            <template #row-select="{ row }">
              <div class="m-cell"><t-checkbox :checked="selectedItems.includes(row.id)" @change="handleItemChose(row.id)"></t-checkbox></div>
            </template>
            <template #cover="{ row }">
              <file-cover :fileData="row" class="file-cover" />
            </template>
            <template #name="{ col, row }">
              <span :class="[(row.typeof==='file')?'':'pointer']" @click="handleListItemClick(row)">{{ row[col.colKey] }}{{row.typeof === 'folder'?'':'.'}}{{row.extension}}</span>
            </template>
            <template #size="{ row }">
              <span v-if="row.typeof === 'file'">{{ bytesToSize(row.size) }}</span>
              <span v-else>-</span>
            </template>
            <template #operation="{ row }">
              <t-space>
                <t-link theme="primary" hover="color" :disabled="selectedItems.includes(row.id)" @click="handleRename(row)">重命名</t-link>
                <t-link theme="danger" hover="color" :disabled="selectedItems.includes(row.id)" @click="handleDelete([row.id])" >删除</t-link>
                <t-link theme="primary" hover="color" :disabled="selectedItems.includes(row.id)" @click="handleCopyMove([row.id], 'move')">剪切</t-link>
                <t-link theme="primary" hover="color" :disabled="selectedItems.includes(row.id) || row.typeof === 'folder'" @click="handleCopyMove([row.id], 'copy')">复制</t-link>
                <t-link theme="primary" hover="color" :disabled="selectedItems.includes(row.id) || row.typeof === 'folder'" @click="downloadFile(row.url, row.name)" >下载</t-link>
                <t-link theme="primary" hover="color" :disabled="selectedItems.includes(row.id) || row.typeof === 'folder'" @click="handleShare(row)">复制链接</t-link>
              </t-space>
            </template>
          </t-table>
        </div>
        <div class="load-more">共{{total}}项文件/文件夹，已加载{{list.length}}项<t-link theme="primary" hover="color" size="small" v-if="list.length < total" @click="loadMore"> ，加载更多 </t-link></div>
      </t-loading>
    </div>
    <t-dialog header='新建文件夹' showOverlay :visible.sync="dialog.createFolder" @confirm="handleCreateFolder">
      <t-input v-model.trim="folderName" placeholder="请输入文件夹名称" :maxlength="20" @enter="handleCreateFolder" :autofocus="focus.createFolder" />
    </t-dialog>
    <t-dialog header="重命名" showOverlay :visible.sync="dialog.rename" @confirm="updateName" :closeOnOverlayClick="false">
      <t-input v-model.trim="tempName" placeholder="请输入新名称" :maxlength="100" @enter="updateName" clearable/>
    </t-dialog>
    <t-dialog theme="danger" header="警示" body="30天内可以在回收站内找回已删文件" :visible.sync="dialog.delete"  @confirm= "confirmDelete" :confirmBtn="{ theme: 'danger' }" />
    <t-dialog theme="info" header="提示" :body="'你将下载'+downloadList.length+'个文件'" :visible.sync="dialog.multiDownload" @confirm="downloadFiles(0, downloadList)" />
    <CopyMove :onShow="copyMove.show" :items="copyMove.items" :type="copyMove.type" @close="copyMove.show = false" @success="handleCopyMoveSuccess" />
  </div>
</template>
<script>

import FileType from '@/components/fileTypes/FileType.vue'
import FileCover from '@/components/fileTypes/FileCover.vue'
import CopyMove from '@/components/fileFolder/CopyMove.vue'
import { AddIcon, CloudUploadIcon, CloseCircleFilledIcon, SearchIcon, CloudDownloadIcon, FileCopyIcon, FolderMoveIcon, Delete1Icon, GridViewIcon, ListIcon, FolderIcon, FolderOpenIcon, RefreshIcon } from 'tdesign-icons-vue'

export default {
  components: {
    SearchIcon,
    AddIcon,
    CloudUploadIcon,
    CloudDownloadIcon,
    CloseCircleFilledIcon,
    Delete1Icon,
    GridViewIcon,
    ListIcon,
    FolderIcon,
    FolderOpenIcon,
    RefreshIcon,
    FileCopyIcon,
    FolderMoveIcon,
    FileType,
    FileCover,
    CopyMove
  },
  props: ['folder'],
  data () {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      keywords: '',
      displayType: 'grid',
      selectedItems: [], // 已选择文件、文件夹
      list: [], // 文件、文件夹列表
      columns: [
        { colKey: 'row-select', title: '', width: 40 },
        { colKey: 'cover', title: '图示', width: 80 },
        { colKey: 'name', title: '名称', ellipsis: true },
        { colKey: 'create_time', title: '上传时间', width: 200 },
        { colKey: 'size', title: '大小' },
        { colKey: 'operation', title: '操作', width: 350 }
      ],
      dialog: {
        createFolder: false,
        rename: false,
        delete: false,
        multiDownload: false
      },
      folderName: '', // 新建文件夹的名称
      folderIdx: '', // 当前文件夹idx
      files: [], // upload组件储存文件列表
      osssts: {}, // 阿里云上传临时凭证
      loading: false, // 加载中
      tempName: '', // 重命名时的input值
      activeRow: {}, // 当前操作行
      deleteItems: [], // 将删除的文件、文件夹id
      downloadList: [], // 文件下载列表
      copyMove: {
        show: false,
        type: '',
        items: []
      },
      focus: {
        createFolder: false
      }
    }
  },
  computed: {
    foldersPath () {
      const path = this.getFolderPath()
      return path.reverse()
    },
    uploadList () {
      return this.$store.getters.getUploadList
    },
    folderInSelectItems () {
      let flag = false
      for (let i = 0; i < this.selectedItems.length; i++) {
        const item = this.selectedItems[i]
        for (let j = 0; j < this.list.length; j++) {
          const element = this.list[j]
          if (item === element.id && element.typeof === 'folder') {
            flag = true
            break
          }
        }
      }
      return flag
    }
  },
  watch: {
    $route (to, from) {
      if (to.params.idx === undefined || to.params.idx === null) {
        this.folderIdx = ''
      } else {
        this.folderIdx = to.params.idx
      }
      this.keywords = ''
      this.getFileFolder()
    }
  },
  methods: {
    createFolder () {
      this.folderName = ''
      this.dialog.createFolder = true
      this.focus.createFolder = true
    },
    refresh () {
      this.getFileFolder()
      this.getFolders()
    },
    onSearch () {
      if (this.keywords !== '') {
        this.$router.push({ name: 'Search', params: { keywords: this.keywords } })
      }
    },
    handleShare (row) {
      const that = this
      navigator.clipboard.writeText(row.url).then(function () {
        that.$message.success('已复制')
      }, function () {
        that.$message.error('复制失败,建议使用最新版本的Chrome浏览器进行操作')
      })
    },
    handldeUploadFileListChange (files) {
      const list = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        let inList = false
        for (let j = 0; j < this.uploadList.length; j++) {
          const item = this.uploadList[j]
          if (file.name === item.name && this.folderIdx === item.pid) {
            // 文件已存在uploadList中
            inList = true
            // console.log('文件已存在')
            // this.$message.warning(item.name + '已在列表中，请勿重复选择')
            break
          }
        }
        if (inList === false) {
          const pname = this.foldersPath[this.foldersPath.length - 1].name
          list.push({ raw: file.raw, size: file.size, name: file.name, pid: this.folderIdx, pname })
        }
      }
      const fileList = [...this.uploadList, ...list]
      this.$store.commit('setUploadList', { uploadList: fileList })
      this.files = []
    },
    getFolderPath () {
      const arr = this.$store.getters.getFolders
      let pid = 0
      let path = []
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (item.idx === this.folderIdx) {
          pid = item.parent_id
          path.push({ name: item.name, idx: this.folderIdx })
          break
        }
      }
      path = this.findParent(pid, arr, path)
      path.push({ name: '所有文件', idx: '' })
      this.selectedItems = [] // 清空所有多选项
      return path
    },
    findParent (pid, list, path) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.id === pid) {
          path.push({ name: item.name, idx: item.idx, parent_id: item.parent_id })
          this.findParent(item.parent_id, list, path)
        }
      }
      return path
    },
    handleCreateFolder () {
      if (this.folderName.length < 1 || this.folderName.length > 20) {
        this.$message.warning('文件名不符合要求')
      } else {
        const that = this
        this.$http.post('folder', { name: this.folderName, parent: this.folderIdx }).then(function (resp) {
          if (resp.data.error === 0) {
            that.$message.success('文件创建成功')
            that.dialog.createFolder = false
            that.getFileFolder()
            that.getFolders()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
    },
    handleItemChose (e) {
      const index = this.selectedItems.indexOf(e)
      if (index === -1) {
        this.selectedItems.push(e)
      } else {
        this.selectedItems.splice(index, 1)
      }
    },
    handleSelectAll (e) {
      if (this.selectedItems.length < this.list.length) {
        const selected = []
        for (let i = 0; i < this.list.length; i++) {
          const item = this.list[i]
          selected.push(item.id)
        }
        this.selectedItems = selected
      } else {
        this.selectedItems = []
      }
    },
    validateSelectedFile (e) {
      if (e.type !== undefined && e.type === 'FILE_OVER_SIZE_LIMIT') {
        for (let i = 0; i < e.files.length; i++) {
          const item = e.files[i]
          this.$message.warning('文件：' + item.name + '太大，' + item.response.error)
        }
      }
    },
    getFileFolder () {
      const that = this
      that.page = 1
      that.loading = true
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        folderIdx: this.folderIdx
      }
      that.$http.get('file_folder', { params }).then(
        function (resp) {
          that.total = resp.data.total
          that.list = resp.data.list
          that.loading = false
        }
      ).catch(function (error) {
        console.log(error.message)
      })
    },
    loadMore () {
      const that = this
      that.page++
      that.loading = true
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        folderIdx: this.folderIdx
      }
      that.$http.get('file_folder', { params }).then(
        function (resp) {
          that.total = resp.data.total
          that.list = [...that.list, ...resp.data.list]
          that.loading = false
        }
      ).catch(function (error) {
        console.log(error.message)
      })
    },
    // 字节转换成其他存储单位
    bytesToSize (bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 B'
      const i = Math.floor(Math.log2(bytes) / 10)
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`
    },
    // 列表中点击文字跳转下级文件夹
    handleListItemClick (file) {
      if (file.typeof === 'folder') {
        this.$router.push({ name: 'Index', params: { idx: file.idx } })
      }
    },
    handleRename (row) {
      this.tempName = row.name
      this.dialog.rename = true
      this.activeRow = row
    },
    // 文件，文件夹重命名
    updateName () {
      if (this.tempName !== '' && this.activeRow.idx !== '') {
        const that = this
        that.$http.put('file_folder/' + this.activeRow.idx, { name: this.tempName }).then(function (resp) {
          if (resp.data.error === 0) {
            that.dialog.rename = false
            that.activeRow.name = that.tempName
            that.$message.success('重命名成功')
            if (that.activeRow.typeof === 'folder') {
              that.getFolders()
            }
          } else {
            that.$message.error(resp.data.msg)
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
    },
    downloadFiles (index, arr) {
      if (index < arr.length) {
        setTimeout(() => {
          this.downloadFile(arr[index].url, arr[index].name)
          this.downloadFiles(index + 1, arr)
        }, 500)
      }
      this.dialog.multiDownload = false
    },
    downloadFile (url, name) {
      const link = document.createElement('a')
      link.href = url
      link.download = name
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 获取系统文件夹信息
    getFolders () {
      const that = this
      that.$http.get('folder').then(function (resp) {
        if (resp.data.list !== undefined) {
          const list = JSON.parse(JSON.stringify(resp.data.list))
          that.$store.commit('setFolders', { folders: list })
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    multiDownload () {
      const downloadList = []
      for (let i = 0; i < this.selectedItems.length; i++) {
        const select = this.selectedItems[i]
        for (let j = 0; j < this.list.length; j++) {
          const item = this.list[j]
          if (item.id === select) {
            if (item.typeof === 'folder') {
              this.$message.warning('不支持直接下载文件夹')
              return
            } else {
              downloadList.push({ url: item.url, name: item.name })
            }
          }
        }
      }
      this.dialog.multiDownload = true
      this.downloadList = downloadList
    },
    handleDelete (items) {
      this.dialog.delete = true
      this.deleteItems = items
    },
    confirmDelete () {
      const that = this
      that.$http.post('delete_file_folder', { items: that.deleteItems }).then(function (resp) {
        if (resp.data.error === 0) {
          that.$message.success('已删除')
          that.dialog.delete = false
          that.getFolders()
          that.getFileFolder()
        }
      }).catch(error => console.log(error))
    },
    handleCopyMove (items, type) {
      this.copyMove.show = true
      this.copyMove.items = items
      this.copyMove.type = type
    },
    handleCopyMoveSuccess () {
      this.getFolders()
      this.getFileFolder()
    }
  },
  mounted () {
    if (this.$route.params.idx !== undefined) {
      this.folderIdx = this.$route.params.idx
      this.keywords = ''
    }
    this.selectedItems = []
    this.getFileFolder()
  }
}
</script>
<style scoped>
.index-page{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.top-bar{
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-bar-right >>> .t-input {
  padding-right: 0;
}
.main{
  flex: 1;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.tool-area{
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-area{
  flex: 1;
  box-sizing: border-box;
  padding-top: 30px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-rows: 150px;
}
.table-area{
  box-sizing: border-box;
  padding-top: 20px;
  height: 100%;
  overflow: hidden;
}
.table-area > div{
  height: 100%;
}
.m-cell{
  height: 100%;
  display: flex;
  align-items: center;
}
.file-cover{
  height: 20px;
  width: 20px;
  overflow: hidden;
}
.pointer{
  cursor: pointer;
}
.load-more{
  height: 40px;
  font-size: 12px;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-empty{
  text-align: center;
  padding: 10% 0;
}
.loading-wrap{
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
