<template>
  <div>
    <div class="file-info">
      <div class="file-info-left">
        <file-cover :fileData="file" class="file-cover" />
        <div class="file-info-text">
          <div class="file-name">{{file.name}}</div>
          <div class="file-size">
            {{bytesToSize(file.size)}}
            <span v-show="file.percent !== 100 && file.status === 'uploading'">正在上传 {{file.percent.toFixed(2) }}%</span>
            <span v-show="file.percent * 1 == 100">已上传至<t-link theme="primary" size="small" @click="$emit('toFolder', file.pid)">{{file.pname}}</t-link></span>
            <span v-show="file.status === 'pause'">已暂停</span>
            <span v-show="file.status === 'cancel'">已取消</span>
          </div>
        </div>
      </div>
      <div class="file-info-right" >
        <template v-if="file.uploadType === 'multiPart'">
          <t-tooltip content="暂停上传" theme="light" v-show="file.percent*1 < 100 && file.status === 'uploading'" >
            <PauseCircleStrokeIcon class="point" @click="pauseUpload" />
          </t-tooltip>
          <t-tooltip content="取消上传" theme="light" v-show="file.percent*1 < 100 && file.status === 'pause'">
            <CloseCircleIcon class="point red" @click="cancelUpload"/>
          </t-tooltip>
          <t-tooltip content="继续上传" theme="light" v-show="file.percent*1 < 100 && file.status === 'pause'">
            <PlayCircleStrokeIcon class="point" @click="continiueUpload"/>
          </t-tooltip>
        </template>
        <CheckCircleIcon class="success" v-show="file.percent === 100" />
      </div>
    </div>
    <t-progress theme="line" size="small" :label="false" :percentage="(file.percent.toFixed(2))*1" v-show="file.percent !== 100 && file.staus !=='cancel'" style="padding:10px 0" />
  </div>
</template>

<script>
import FileCover from '@/components/fileTypes/FileCover.vue'
import { PauseCircleStrokeIcon, CloseCircleIcon, PlayCircleStrokeIcon, CheckCircleIcon } from 'tdesign-icons-vue'
export default {
  props: ['file'],
  components: {
    FileCover,
    PauseCircleStrokeIcon,
    CloseCircleIcon,
    PlayCircleStrokeIcon,
    CheckCircleIcon
  },
  methods: {
    pauseUpload () {
      console.log('cancel')
      this.$message.warning('文件已暂停上传')
      this.$emit('pause')
    },
    continiueUpload () {
      this.$emit('continiue')
    },
    cancelUpload () {
      this.$emit('cancel')
    },
    // 字节转换成其他存储单位
    bytesToSize (bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 B'
      const i = Math.floor(Math.log2(bytes) / 10)
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`
    }

  }
}
</script>

<style scoped>
.file-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.file-info-left{
  display: flex;
  height: 40px;
  flex: 1;
  overflow: hidden;
}
.file-cover{
  width: 40px;
  margin-right: 15px;
}
.file-info-text{
  flex: 1;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.file-name{
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-size{
  font-size: 12px;
  line-height: 16px;
}
.file-info-right{
  font-size: 20px;
  width: 70px;
  text-align: right;
}
.point{
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
.point:hover{
  background-color: #f1f1f1;
  color: black;
}
.success{
  color: #2ba471;
}
.red{
  color: #e37318;
}
</style>
