<template>
<!-- 返回文件夹、文件缩略图 主要用在list -->
  <div class="file-cover">
    <t-image-viewer :images="[fileData.url]" v-if="isImage" closeOnOverlay>
        <template #trigger="{ open }">
          <t-image :src="coverImage" fit="cover" class="cover-image" @click="open"><template #loading><LoadingIcon /></template></t-image>
        </template>
      </t-image-viewer>
    <t-image :src="coverImage" fit="fill" class="cover-image" v-else><template #loading><LoadingIcon /></template></t-image>
  </div>
</template>

<script>
import { LoadingIcon } from 'tdesign-icons-vue'
export default {
  props: [
    'fileData'
  ],
  components: {
    LoadingIcon
  },
  computed: {
    isImage () {
      const images = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp']
      return images.includes(this.fileData.extension)
    },
    coverImage () {
      let url = 'images/fileTypes/unknown.svg'
      if (this.fileData.typeof !== undefined) {
        if (this.fileData.typeof === 'folder') {
          url = 'images/fileTypes/folder.svg'
        } else {
          const images = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp']
          if (images.includes(this.fileData.extension)) {
            if (this.fileData.url !== null && this.fileData.url !== '') {
              url = this.fileData.url
            } else {
              url = 'images/fileTypes/img.svg'
            }
          } else {
            const fileImages = ['psd', 'ai', 'txt', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'zip', 'rar', '7z', 'mp3', 'wav', 'acc', 'flac', 'mp4', 'mov', 'avi', 'cad']
            if (fileImages.includes(this.fileData.extension)) {
              url = 'images/fileTypes/' + this.fileData.extension + '.svg'
            }
          }
        }
      }
      return url
    }
  }
}
</script>

<style scoped>
.cover-image{
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
</style>
