import axios from 'axios'
import { MessagePlugin } from 'tdesign-vue'
import router from './router'

// 创建axios实例
const service = axios.create({
  // baseURL: 'https://files.idatamind.com/api/', // api的base_url
  // baseURL: 'https://files.wegrowtrading.com/api/', // api的base_url
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 5000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  // 在发出请求前进行相关配置，如设置token
  const token = sessionStorage.getItem('token')
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, error => {
  // 处理请求错误
  console.log(error)
  Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(response => {
  // 处理响应数据
  if (response.data.error !== undefined && response.data.error === 1) {
    MessagePlugin('error', { content: response.data.msg })
  }
  if (response.data.error !== undefined && (response.data.error === 401 || response.data.error === 400)) {
    router.replace({ path: '/login' })
  }
  return response
}, error => {
  // 处理响应错误
  MessagePlugin('error', { content: '网络错误' })
  return Promise.reject(error)
})

export default service
