<template>
  <div>
    <t-submenu :value="folder.idx" v-if="folder.children.length > 0">
      <template #icon>
        <FolderOpenIcon v-if="$route.params.idx === folder.idx" />
        <FolderIcon v-else @click="goto(folder.idx)" />
      </template>
      <template #title>
        <div class="kuan" @click="goto(folder.idx)">{{folder.name}}</div>
      </template>
      <SelfMenu v-for="item in folder.children" :key="item.id" :folder="item"/>
    </t-submenu>
    <t-menu-item :value="folder.idx" v-else  @click="goto(folder.idx)">
      <template #icon>
        <FolderOpenIcon v-if="$route.params.idx === folder.idx" />
        <FolderIcon v-else />
      </template>
      {{folder.name}}
    </t-menu-item>
  </div>
</template>

<script>
import { FolderOpenIcon, FolderIcon } from 'tdesign-icons-vue'
import SelfMenu from '@/components/SelfMenu.vue'
export default {
  name: 'SelfMenu',
  props: ['folder', 'routeName'],
  components: {
    FolderOpenIcon,
    FolderIcon,
    SelfMenu
  },
  methods: {
    goto (idx) {
      this.$router.push({ name: this.routeName, params: { idx } })
    }
  }
}
</script>
<style scoped>
.kuan{
  width: 100%;
}
* >>>.t-menu__content{
  width: 100%;
}
</style>
