<template>
  <t-dialog :header="title" showOverlay :visible="onShow" @confirm="handleConfirm" @close="$emit('close')" :confirmBtn="{content: title+'此处', loading: isloading}" attach="body">
    <folder-list @chose="handleFolderChose" :reset="randomNumber" />
  </t-dialog>
</template>

<script>
import FolderList from '@/components/FolderList.vue'
export default {
  name: 'copy-move',
  props: ['items', 'type', 'onShow'],
  components: {
    FolderList
  },
  watch: {
    items () {
      this.toPath = 0
    },
    onShow (newV, oldV) {
      this.show = newV
      if (newV === true) {
        const d = new Date()
        this.randomNumber = d.getTime()
      }
    }
  },
  data () {
    return {
      isloading: false,
      toPath: 0,
      randomNumber: 0
    }
  },
  computed: {
    title () {
      return this.type === 'copy' ? '复制到' : '剪切到'
    }
  },
  methods: {
    handleConfirm () {
      const params = { toPath: this.toPath, items: this.items }
      const that = this
      that.isloading = true
      const url = this.type === 'copy' ? 'file_copy' : 'file_folder_move'
      that.$http.post(url, params).then(function (resp) {
        if (resp.data.error === 0) {
          that.$message.success('操作成功')
          that.$emit('success')
          that.$emit('close')
          that.isloading = false
        }
      })
    },
    handleFolderChose (path) {
      this.toPath = path
    }
  }
}
</script>

<style scoped>

</style>
