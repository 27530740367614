<template>
  <div class="folder-list-page">
    <div class="folder-title"><RollbackIcon class="back-icon" @click="goback" v-show="activeId !== 0" />{{activeName}}</div>
    <div class="folder-list">
      <div class="folder-item" v-for="(folder, index) in folderList" :key="index" @click="activeId = folder.id">
        <img src="/images/fileTypes/folder.svg" alt="文件夹" class="folder-icon">
        <div class="folder-name">{{folder.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { RollbackIcon } from 'tdesign-icons-vue'
export default {
  props: ['reset'],
  components: {
    RollbackIcon
  },
  computed: {
    folders () {
      return this.$store.getters.getFolders
    },
    folderList () {
      const list = []
      for (let i = 0; i < this.folders.length; i++) {
        const item = this.folders[i]
        if ((item.parent_id * 1) === (this.activeId * 1)) {
          list.push(item)
        }
      }
      return list
    },
    activeName () {
      let name = '所有文件'
      for (let i = 0; i < this.folders.length; i++) {
        const folder = this.folders[i]
        if (folder.id === this.activeId) {
          name = folder.name
        }
      }
      return name
    }
  },
  watch: {
    reset () {
      this.activeId = 0
    },
    activeId (newV, oldV) {
      let idx = ''
      for (let i = 0; i < this.folders.length; i++) {
        const folder = this.folders[i]
        if (folder.id === newV) {
          idx = folder.idx
        }
      }
      this.$emit('chose', idx)
    }
  },
  data () {
    return {
      activeId: 0
    }
  },
  methods: {
    goback () {
      for (let i = 0; i < this.folders.length; i++) {
        const folder = this.folders[i]
        if (folder.id === this.activeId) {
          this.activeId = folder.parent_id
          break
        }
      }
    }
  }
}
</script>

<style scoped>
.folder-title{
  display: flex;
  align-items: center;
  font-weight: bold;
}
.back-icon{
  font-size: 20px;
  padding-right: 5px;
  cursor: pointer;
}
.back-icon:hover{
  color: #0052d9;
}
.folder-list{
  box-sizing: border-box;
  padding-top: 20px;
  box-sizing: border-box;
  max-height: 400px;
}
.folder-item{
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin: 0 10px 20px 0;
}
.folder-item:hover{
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.folder-icon{
  width: 30px;
  max-height: 30px;
}
.folder-name{
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding-left: 15px;
}
</style>
